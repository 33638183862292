import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import NotFoundLogo from '../../components/NotFoundLogo';

class PageNotFound extends React.Component<RouteComponentProps> {
  render() {
    return (
      <div className="w-full lg:w-container lg:mx-auto lg:mb-3 py-2">
        <div className="d-b flex flex-flow-column items-center content-center justify-center ta-center">
          <NotFoundLogo className="lg:w-3/5" />
          <h1 className="lg:w-full">
            Sorry, <br />
            The page you requested was not found
          </h1>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
