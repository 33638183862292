import * as React from 'react';

interface Props {
  className?: string;
}

const NotFoundLogo: React.FunctionComponent<Props> = (props) => {
  return (
    <img
      className={props.className}
      src="https://inkp-production.32pt.com/public/app/Inkpop+Emotion+Error-01.png"
    />
  );
}

export default NotFoundLogo;
